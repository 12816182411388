<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">EMPLOYEE REPORTS</span>
          <v-spacer></v-spacer>
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px">
                  *Select the options to filter the results
                </span>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-text-field
                  v-model="keyword"
                  outlined
                  dense
                  hide-details
                  clearable
                  label="Search Employee"
                ></v-text-field>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-autocomplete
                  v-model="positionid"
                  :items="positionList"
                  clearable
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Position"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-autocomplete
                  v-model="gradeFilter"
                  :items="filteredGradeList"
                  clearable
                  item-text="grade"
                  item-value="_id"
                  outlined
                  dense
                  label="Choose Grade"
                ></v-autocomplete>
              </v-flex>

              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <!-- Checkboxes for filtering -->
              <v-flex xs12 pt-5>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.salary"
                      label="Salary"
                    ></v-checkbox>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md4 lg3 xl2 >
                                <v-checkbox v-model="filters.phone" label="Phone"></v-checkbox>
                              </v-flex> -->
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.pancard"
                      label="Pancard"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox v-model="filters.pmr" label="PMR"></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.grade"
                      label="Grade"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.qualification"
                      label="Qualification"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.bankdetails"
                      label="Bank Details"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.adhar"
                      label="Aadhar"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.PreExp"
                      label="Previous Experience"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.totalExp"
                      label="total Experience"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.monthlyCTC"
                      label="Monthly CTC"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.education"
                      label="Education"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.disciplinaryAction"
                      label="Disciplinary Action"
                    ></v-checkbox>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.salaryComparison"
                      label="Salary Comparison"
                    ></v-checkbox>
                  </v-flex> -->
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center text-end>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-btn
                      style="font-family: kumbhBold"
                      dark
                      color="#005f32"
                      @click="goToSalaryComparison"
                    >
                      Salary Comparison
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4 pt-2 pt-sm-0>
                    <v-btn
                      style="font-family: kumbhBold"
                      dark
                      color="#005f32"
                      @click="employeeExcelDownload()"
                    >
                      Download Excel
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start px-4 v-if="data.length > 0">
          <v-flex xs12 pt-3>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Employee Id
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Name
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Position
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Grade
                    </th>

                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Email
                    </th>

                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Phone
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Experience in WTI
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <template>
                    <tr
                      class="text-left align-center"
                      style="cursor: pointer"
                      v-for="item in data"
                      :key="item._id"
                    >
                      <td style="text-align: justify">
                        <span
                          v-if="item.employeeId"
                          class=""
                          style="font-family: kumbhMedium; cursor: pointer"
                        >
                          {{ item.employeeId }}
                        </span>
                      </td>

                      <td>
                        <span
                          v-if="item.name"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.name }}</span
                        >
                      </td>

                      <td>
                        <span class="" style="font-family: kumbhMedium">{{
                          item.position
                        }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{
                          item.grade
                        }}</span>
                      </td>

                      <td>
                        <span class="" style="font-family: kumbhMedium">{{
                          item.email
                        }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{
                          item.phone
                        }}</span>
                      </td>
                      <td>
                        <span class="" style="font-family: kumbhMedium">{{
                          item.yearofexperience
                        }}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    keyword: "",
    fromDate: "",
    toDate: "",
    menu1: false,
    menu2: false,
    positionid: "",
    gradeFilter: "",
    gradeFilterList: [],
    positionList: [],
    filters: {
      salary: false,
      //   phone: false,
      qualification: false,
      PreExp: false,
      totalExp: false,
      monthlyCTC: false,
      education: false,
      disciplinaryAction: false,
      salaryComparison: false,

      pancard: false,
      pmr: false,
      grade: false,
      bankdetails: false,
      adhar: false,
    },
    showsnackbar: false,
    ServerError: false,
    msg: null,
    appLoading: false,
    g: false,

    rules: {
      required: (value) => !!value || "Required.",
    },
    data: [],
    pages: 0,
    currentPage: 1,
    totalData: 0,
  }),
  computed: {
    filteredGradeList() {
      // Filter out items with null value
      return this.gradeFilterList.filter(
        (item, index) => index !== 0 && item._id !== null
      );
    },
  },

  mounted() {
    this.getData();
    if (this.position === "62a2de91ecd959516cd3b0e6") {
      this.getData();
    }
    this.getPositions();
    this.getGrades();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage = 1;
    },
    positionid() {
      this.getData();
      this.currentPage = 1;
    },
    gradeFilter() {
      this.getData();
    },
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  methods: {
    goToSalaryComparison() {
      this.$router.push({
        path: "/ExcelReports/salary-comparison-report", // Replace with your actual route path
      });
    },
    getGrades() {
      this.appLoading = true;
      axios({
        url: "/grade/dropdown/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   isDetailed: true,
        //   departmentIds: this.department,
        //   projectIds: this.project,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.gradeFilterList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPositions() {
      this.appLoading = true;
      axios({
        url: "/getalllist/position/v1",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   isDetailed: true,
        //   departmentIds: this.department,
        //   projectIds: this.project,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.positionList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/report/employee/details",
        method: "post",
        data: {
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          positionid: this.positionid,
          gradeFilter: this.gradeFilter,
          page: this.currentPage,
          limit: 20,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          this.totalData = response.data.total;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    employeeExcelDownload() {
      axios({
        method: "GET",
        url: "/report/employee/details/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          positionid: this.positionid,
          gradeFilter: this.gradeFilter,
          salary: this.filters.salary,
          qualification: this.filters.qualification,
          PreExp: this.filters.PreExp,
          totalExp: this.filters.totalExp,
          monthlyCTC: this.filters.monthlyCTC,
          education: this.filters.education,
          disciplinaryAction: this.filters.qualification,
          salaryComparison: this.filters.salaryComparison,

          pancard: this.filters.pancard,
          pmr: this.filters.pmr,
          grade: this.filters.grade,
          bankdetails: this.filters.bankdetails,
          adhar: this.filters.adhar,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employees Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>